export default {
  opertionType: {
    0X8300: '消息类型-消息内容',
    0x8103: '设置终端参数',
    0x8899: '终端升级',
    0xFEEB: '设置IC卡参数',
    0x0055: '设置最高速度(km/h)',
    0x005b: '设置预超速提前量(km/h)',
    0x0056: '设置超速持续时间(s)',
    0x0057: '设置连续驾驶时间(分钟)',
    0x005c: '设置预疲劳提前量(分钟)',
    0x0058: '设置当天累计驾驶时间(分钟)',
    0x0059: '设置最小休息时间(分钟)',
    0x0001: '设置心跳间隔(s)',
    0x0010: '设置主服务器APN ',
    0x0013: '设置主服务器IP或域名 ',
    0x0018: '设置主服务器端口 ',
    0x9101: '通道号',
    0x9205: '通道号-时间',
    0x9201: '通道号',
  }

}
