<template>
  <div class="dispatch-statistics" ref="dispatchStatistics">
    <div class="form-area" ref="form">
      <!-- :showCommand="form.type === 3" -->
      <!-- :isNeedRequire="form.type === 3" 车辆必填取消，公司和车辆二选一 -->
      <analysis-form
        @getFormVal="getFormVal"
        :showMonth="form.type != 3"
        :showCompany="form.type == 1 || form.type == 3"
        :showCar="form.type != 1"
        :timeLimit="7"
        :showCommand="true"        
        :formType="form.type"
      >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 2"
          :disabled="carArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData(2)"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 3"
          :disabled="carDetialsArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData(3)"
          >导出</el-button
        >
      </analysis-form>
      <el-radio-group v-model="form.type" @change="changeState">
        <el-radio :label="v.label" v-for="(v, i) in options" :key="i">{{
          v.value
        }}</el-radio>
      </el-radio-group>
    </div>
    <!-- 企业统计 -->
    <div class="form-table" v-if="form.type === 1 && companyArr.length > 0">
      <el-table :data="companyArr" :height="tableHeight" style="width: 100%">
        <el-table-column type="index" width="70" label="序号"></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属企业"
          :show-overflow-tooltip="true"
          width="200px"
        ></el-table-column>
        <el-table-column
          v-for="(obj, key) in companyArr[0].list"
          :key="key"
          width="120px"
          :show-overflow-tooltip="true"
          :label="Object.keys(obj)[0]"
        >
          <template>{{ obj[Object.keys(obj)[0]] }}</template>
        </el-table-column>
        <el-table-column prop="total" label="总计"></el-table-column>
      </el-table>
    </div>

    <!--车辆统计 -->
    <div class="form-table" v-if="form.type === 2 && carArr.length > 0">
      <el-table :data="carArr" :height="tableHeight" style="width: 100%">
        <el-table-column type="index" width="70" label="序号"></el-table-column>
        <el-table-column
          prop="cph"
          label="车牌号"
          :width="100 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          label="车牌颜色"
          prop="licensePlateColorStr"
          :width="80 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="vehicleTypeStr"
          label="车辆类型"
          :formatter="formatNull"
          :width="130 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属企业"
          :show-overflow-tooltip="true"
          width="200px"
        ></el-table-column>
        <el-table-column
          v-for="(obj, key, index) in carArr[0].map"
          :key="index"
          width="120px"
          :show-overflow-tooltip="true"
          :label="key"
        >
          <template slot-scope="scope">{{
            carArr[scope.$index].map[key]
          }}</template>
          <!-- <template>{{ obj }}</template> -->
        </el-table-column>
        <el-table-column prop="total" label="总计"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 车辆详情 -->
    <div v-if="form.type === 3" class="form-table">
      <el-table :data="carDetialsArr" style="width: 100%" :height="tableHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column
          prop="cph"
          label="车牌号"
          :width="100 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          label="车牌颜色"
          prop="licensePlateColorStr"
          :width="80 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="vehicleTypeStr"
          label="车辆类型"
          :formatter="formatNull"
          :width="130 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属道路运输企业"
          :width="180 * this.$store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="commandDetail"
          label="指令"
          :formatter="formatNull"
          :width="150 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          label="指令"
          prop="command"
          :formatter="formatCommand"
          :width="300 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="sendTime"
          label="发送时间"
          :width="150 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
          :formatter="formatNull"
        ></el-table-column>
        <el-table-column prop="userName" label="发送人员"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import analysisForm from "./components/form.vue";

import {
  scheduleVehicleDetail,
  scheduleVehicleCount,
  scheduleCompanyCount,
} from "@/api/lib/gps-api.js";

import { getCurent, formatDate } from "@/common/utils/index";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import command from '@/api/static/command'

export default {
  name: "dispatchStatistics",
  components: {
    analysisForm,
  },
  data () {
    return {
      tableHeight: 0,
      total: 0,

      options: [
        { value: "企业统计表", label: 1, queryPath: "" },
        { value: "车辆统计表", label: 2, queryPath: "/schedule/exportVehicleCount" },
        { value: "车辆明细表", label: 3, queryPath: "/schedule/exportVehicleDetail" },
      ],

      form: {
        vehicleNos: [],
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
        type: 3,
        month: null,
      },
      exportForm: {}, //导出需要的数据
      downLoadStatus: false, //导出
      //企业
      companyArr: [],
      //车辆统计
      carArr: [],
      carDetialsArr: [],
      typeObj: null,
      modelObj: null,
      opertionType: command.opertionType
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.dispatchStatistics.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      this.tableHeight = wholeHeight - formHeight - 30 - 10 - 24;
    },
    //导出
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let item = this.options.find((res) => res.label === key);

      let data = {
        baseUrl: "report",
        fileName: `车辆调度${item.value}-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: item.queryPath,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    /**请求类型切换 */
    changeState () {
      this.reset();
    },
    /**请求参数清空*/
    reset () {
      this.form.currentPage = 1;
      this.form.pageSize = 10;

      this.total = 0;
      this.companyArr = [];
      this.carArr = [];
      this.carDetialsArr = [];
    },
    /**点击查询 */
    getFormVal (v) {
      this.reset();
      this.form = { ...this.form, ...v };
      // 操作内容包含文本信息下发，则有终端类型，否则无值
      if (!this.form.zls.includes('0x8300')) {
        delete this.form.deviceType
      }
      this.onSubmit();
    },
    /**查询 */
    onSubmit () {
      let data = { ...this.form };

      switch (this.form.type) {
        case 1:
          /**企业统计表 */
          delete data.vehicleNos;
          delete data.beginTime;
          delete data.endTime;
          delete data.type;
          this.getcompanyArr(data);
          break;
        case 2:
          /**车辆统计表 */
          delete data.companyId;
          delete data.beginTime;
          delete data.endTime;
          delete data.type;
          this.getCarArr(data);
          break;
        case 3:
          /**车辆明细表 */
          // delete data.companyId;//迭代后要加公司了
          delete data.month;
          delete data.type;
          this.getCarDetialsArr(data);

          break;

        default:
          break;
      }
      this.exportForm = { ...data };
    },

    /**企业统计表 */
    getcompanyArr (data) {
      let self = this;
      scheduleCompanyCount(data).then((res) => {
        if (res) {
          self.companyArr = [res.data];
        }
      });
    },
    /**车辆统计表 */
    getCarArr (data) {
      let self = this;
      scheduleVehicleCount(data).then((res) => {
        if (res) {
          self.carArr = res.data.list;
          self.total = res.data.total;
        }
      });
    },
    /**车辆明细表 */
    getCarDetialsArr (data) {
      if(!data.companyId && !data.vehicleNos.length){
        this.$message.warning('请选择公司车队或者选择车辆！');
        return
      }
      let self = this;
      scheduleVehicleDetail(data).then((res) => {
        if (res.code === 1000) {
          self.carDetialsArr = res.data.list || [];
          self.total = res.data.total;
        } else {
          self.carDetialsArr = [];
          self.total = 0;
          this.$message.error(res.msg);
        }
      });
    },

    /**数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue == "" ? "-" : cellValue;
    },
    /**指令详细信息 */
    formatCommand (row, column, cellValue) {
      let detail = '-'
      let text = ''
      let params = null, cmdText = ''
      switch (cellValue) {
        //文本信息下发
        case 0X8300:
          params = row.params.replace("[", '').replace("]", '').split(",")
          cmdText = this.opertionType[cellValue]
          let textTetail = params.slice(1, params.length)
          text += `${cmdText.split('-')[0]}：${params[0]},`
          text += `${cmdText.split('-')[1]}：${textTetail.join(",")}`
          detail = text
          break;
        //设置终端参数
        case 33027:
          params = row.params.replace("[", '').replace("]", '').split(",")
          for (let i = 0; i < params.length / 2; i++) {
            const element = Number(params[i * 2])
            cmdText = this.opertionType[element]
            // debugger
            switch (element) {
              case 0x005B: // 预警提前量-超速
                params[i * 2 + 1] = params[i * 2 + 1] / 10
                break
              case 0x0057: // 设置连续驾驶时间门限
                params[i * 2 + 1] = params[i * 2 + 1] / 60
                break
              case 0x0058: // 设置当天累计驾驶时间门限
                params[i * 2 + 1] = params[i * 2 + 1] / 60
                break
              case 0x0059: // 设置最小休息时间
                params[i * 2 + 1] = params[i * 2 + 1] / 60
                break
              case 0x005C: // 预警提前量-疲劳
                params[i * 2 + 1] = params[i * 2 + 1] / 60
                break
              default:
                break
            }
            text += `${cmdText}：${params[i * 2 + 1]},`
          }
          detail = text
          break;
        //实时音视频传输请求
        case 37121:
          params = row.params.split(",")
          cmdText = this.opertionType[cellValue]
          detail = `${cmdText}：${params[3]}`
          break;
        //历史视频 查询终端资源列表
        case 0x9205:
          params = row.params.replace("[", '').replace("]", '').split(",")
          cmdText = this.opertionType[cellValue]
          let time = `${formatDate(new Date(params[1] * 1000))} ~ ${formatDate(new Date(params[2] * 1000))}`
          text += `${cmdText.split('-')[0]}：${params[0]},`
          text += `${cmdText.split('-')[1]}：${time}`

          detail = text
          break;
        //历史视频 远程录像回放请求
        case 0x9201:
          params = row.params.split(",")
          cmdText = this.opertionType[cellValue]
          detail = `${cmdText}：${params[3]}`
          break;

        default:
          detail = this.opertionType[cellValue] ? `${this.opertionType[cellValue]} : ${row.params}` : '-'
          break;
      }
      return detail
    },


    /** 分页参数切换*/
    handleSizeChange (val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      if (this.carArr.length === 0 && this.carDetialsArr.length === 0) return;
      this.onSubmit();
    },
    /**分页参数切换 */
    handleCurrentChange (val) {
      this.form.currentPage = val;
      this.onSubmit();
    },
  },
  created () { },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.dispatch-statistics {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.dispatch-statistics {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>
