<template>
  <div class="analysisForm">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      class="demo-form-inline"
      label-position="left"
      label-width="100px"
    >
      <!-- 公司车队： -->
      <el-form-item label="公司车队：" v-show="showCompany">
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="false"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" v-show="showCar" :prop="isNeedRequire?'vehicleNos':''">
        <car-tree
          ref="carSearchTree"
          :label="label"
          @getData="getVehIds"
          :companyIdList="form.companyIdList"
          :isNeedCompanyQuery="false"
        ></car-tree>
      </el-form-item>
      <!-- 操作内容： -->
      <el-form-item label="操作内容：" v-show="showCommand">
        <el-select
          clearable
          multiple
          collapse-tags
          v-model="form.zls"
          placeholder="请选择"
        >
          <el-option
            v-for="item in commandList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      
      <!-- 选择月份 -->
      <el-form-item label="选择月份：" v-show="showMonth" prop="month">
        <el-date-picker
          v-model="form.month"
          type="month"
          placeholder="选择月"
        ></el-date-picker>
      </el-form-item>

      <!-- 选择时间： -->
      <!-- 郭鑫杰：车辆明细表选择公司条件时，时间范围只能选择一天 2023/10/23 -->
      <el-form-item label="选择时间：" prop="time" v-show="!showMonth">
        <el-date-picker
          cellClassName="timeRangePicker"
          v-model="form.time"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>

      <!-- 文本信息下发来源：adas下发，调度屏下发 -->
      <el-form-item label="下发终端类型：" v-if="form.zls.includes('0x8300')">
        <el-select
          clearable
          v-model="form.deviceType"
          placeholder="请选择"
        >
          <el-option 
            v-for="item in TextDeliverySourceList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label-width="0">
        <el-button type="primary" size="small" @click="onSearch()"
          >查询</el-button
        >
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 报表查询form组件
 * 公司与车辆查询联动
 * 公司单选
 */
import carTree from '@/components/carTree/carSearchTreeNew.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearchNew.vue'
import {
  getRangeDay,
  formatDate,
  formatDay,
  formatMonth,
  checkTimeLimitDay
} from '@/common/utils/index'
import { queryDictsByCodes } from "@/api/lib/api.js";

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    //tab类型
    formType:{
      type: Number,
      default: 3
    },
    timeLimit: {
      type: Number,
      default: 31
    },
    formatDay: {
      type: Boolean,
      default: false
    },
    /**车辆集合需要参数（车架号/车辆id) */
    label: {
      type: String,
      default: 'vehicleNo'
    },
    showCar: {
      type: Boolean,
      default: true
    },
    showCompany: {
      type: Boolean,
      default: true
    },
    showMonth: {
      type: Boolean,
      default: false
    },
    //车辆明细--选择操作方式
    showCommand: {
      type: Boolean,
      default: false
    },
    //选择车辆是否是必填
    isNeedRequire:{
      type: Boolean,
      default: false
    }
  },

  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (
        new Date() <= value[0] ||
        new Date() <= value[1]
      ) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }
    const validateDateLimit = (rule, value, callback) => {
      let limit = this.timeLimit;//限制只能选择limit天范围
      if(this.form.companyIdList.length && this.formType == 3) limit = 1;
      if (!checkTimeLimitDay(value[0], value[1],limit)) {
        callback(new Error(`只能查询${limit}天内的数据`))
      } else {
        callback()
      }
    }
    return {
      terminalNos: [],
      commandList: [],
      TextDeliverySourceList:[
      {
          dictValue: 'adas下发',
          dictCode: '0',
        },
        {
          dictValue: '调度屏下发',
          dictCode: '2',
        },
      ],
      form: {
        time: getRangeDay(true),
        vehicleNos: [],
        companyIdList: [],
        zls: [],
        month: new Date(),
        deviceType:""
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = getRangeDay(true)[1]
              const start = getRangeDay(true)[1] - 3600 * 1000 * 24 * 6
              picker.$emit('pick', [start, end])
            }
          },
          // {
          //   text: '最近一个月',
          //   onClick (picker) {
          //     const end = getRangeDay(true)[1]
          //     const start = getRangeDay(true)[1] - 3600 * 1000 * 24 * 30
          //     picker.$emit('pick', [start, end])
          //   }
          // }
        ]
      },
      formRules: {
        vehicleNos:[
          { required:true ,type:'array', message: '请选择车辆', trigger: 'change' },
        ],
        time: [
          { required: true, message: '请选择时间范围', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'change' },
          { validator: validateDateLimit, trigger: 'change' }
        ],
        month: [{ required: true, message: '请选择月份', trigger: 'blur' }]
      },
    }
  },
  watch:{
    isNeedRequire(v){
      !v && this.$refs.form.clearValidate();
    }
  },
  methods: {
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      this.form.companyIdList = val.length === 0 ? [] : [...val]
      //车辆选择数据清除
      this.form.vehicleNos = []
      if (this.showCar) this.$refs.carSearchTree.resetTree()
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      this.form.vehicleNos = [...val]
    },

    onSearch () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {}
          if (this.formatDay) {
            data = {
              ...this.form,
              beginTime: formatDay(this.form.time[0]),
              endTime: formatDay(this.form.time[1])
            }
          } else {
            data = {
              ...this.form,
              beginTime: formatDate(this.form.time[0]),
              endTime: formatDate(this.form.time[1]).split(' ')[0] + ' 23:59:59'
            }
          }
          if (this.showMonth) {
            data.month = formatMonth(this.form.month)
          }
          if (this.showCommand) {
            data.zls = this.form.zls
          }
          data.companyId =
            this.form.companyIdList.length > 0
              ? this.form.companyIdList[0]
              : null
          delete data.time
          delete data.companyIdList 
          this.$emit('getFormVal', data)
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "ZL" }).then((res) => {
        if (res.code === 1000) {
          this.commandList = res.data.ZL
        }
      });
    },
  },
  created () {
    this.getDicts();
  },
  mounted () { }
}
</script>
<style lang="scss" scoped>
  .demo-form-inline{
    grid-template-columns: 25% 22% 27% 22%;
  gap: 5px 10px;
}
</style>
