var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "dispatchStatistics", staticClass: "dispatch-statistics" },
    [
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "analysis-form",
            {
              attrs: {
                showMonth: _vm.form.type != 3,
                showCompany: _vm.form.type == 1 || _vm.form.type == 3,
                showCar: _vm.form.type != 1,
                timeLimit: 7,
                showCommand: true,
                formType: _vm.form.type
              },
              on: { getFormVal: _vm.getFormVal }
            },
            [
              _vm.form.type === 2
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.carArr.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportData(2)
                        }
                      }
                    },
                    [_vm._v("导出")]
                  )
                : _vm._e(),
              _vm.form.type === 3
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.carDetialsArr.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportData(3)
                        }
                      }
                    },
                    [_vm._v("导出")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-radio-group",
            {
              on: { change: _vm.changeState },
              model: {
                value: _vm.form.type,
                callback: function($$v) {
                  _vm.$set(_vm.form, "type", $$v)
                },
                expression: "form.type"
              }
            },
            _vm._l(_vm.options, function(v, i) {
              return _c("el-radio", { key: i, attrs: { label: v.label } }, [
                _vm._v(_vm._s(v.value))
              ])
            }),
            1
          )
        ],
        1
      ),
      _vm.form.type === 1 && _vm.companyArr.length > 0
        ? _c(
            "div",
            { staticClass: "form-table" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.companyArr, height: _vm.tableHeight }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "70", label: "序号" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyName",
                      label: "所属企业",
                      "show-overflow-tooltip": true,
                      width: "200px"
                    }
                  }),
                  _vm._l(_vm.companyArr[0].list, function(obj, key) {
                    return _c(
                      "el-table-column",
                      {
                        key: key,
                        attrs: {
                          width: "120px",
                          "show-overflow-tooltip": true,
                          label: Object.keys(obj)[0]
                        }
                      },
                      [[_vm._v(_vm._s(obj[Object.keys(obj)[0]]))]],
                      2
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "total", label: "总计" }
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.form.type === 2 && _vm.carArr.length > 0
        ? _c(
            "div",
            { staticClass: "form-table" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.carArr, height: _vm.tableHeight }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "70", label: "序号" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cph",
                      label: "车牌号",
                      width: 100 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "车牌颜色",
                      prop: "licensePlateColorStr",
                      width: 80 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vehicleTypeStr",
                      label: "车辆类型",
                      formatter: _vm.formatNull,
                      width: 130 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyName",
                      label: "所属企业",
                      "show-overflow-tooltip": true,
                      width: "200px"
                    }
                  }),
                  _vm._l(_vm.carArr[0].map, function(obj, key, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        width: "120px",
                        "show-overflow-tooltip": true,
                        label: key
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  _vm._s(_vm.carArr[scope.$index].map[key])
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "total", label: "总计" }
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      "page-size": _vm.form.pageSize,
                      "current-page": _vm.form.currentPage
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.form.type === 3
        ? _c(
            "div",
            { staticClass: "form-table" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.carDetialsArr, height: _vm.tableHeight }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "50" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cph",
                      label: "车牌号",
                      width: 100 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "车牌颜色",
                      prop: "licensePlateColorStr",
                      width: 80 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vehicleTypeStr",
                      label: "车辆类型",
                      formatter: _vm.formatNull,
                      width: 130 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyName",
                      label: "所属道路运输企业",
                      width: 180 * this.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "commandDetail",
                      label: "指令",
                      formatter: _vm.formatNull,
                      width: 150 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "指令",
                      prop: "command",
                      formatter: _vm.formatCommand,
                      width: 300 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sendTime",
                      label: "发送时间",
                      width: 150 * _vm.$store.state.vehicle.screenCoefficient,
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatNull
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "userName", label: "发送人员" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      "page-size": _vm.form.pageSize,
                      "current-page": _vm.form.currentPage
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }